import React from 'react';

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <a href="/">
                                    <img src="/assets/images/logo/logo.png" alt="Botikir"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>Copyright © 2021 Silibrain LLC. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="inner text-justify">
                    <div className="text">
                        <p style={{fontSize:"0.5em"}}>  Nuestros servicios están sujetos a los términos y condiciones que se establecen a continuación. Su uso de los servicios de Botikir indica que acepta estar sujeto a los términos y condiciones aquí contenidos. La empresa nunca vende su información personal como número de teléfono, dirección o identificación de correo electrónico a terceros. Es posible que cambiemos los términos de vez en cuando y es la versión actual la que se aplicará a cada pedido cuando lo realice. Le notificaremos los cambios en los Términos por correo electrónico.
                        En Botikir, los clientes deben proporcionar una imagen de prescripción para comprar algunos medicamentos. En caso de cualquier error o daño, su producto debe ser informado a hola@botikir.com dentro de las 24 horas. Si no informamos en el momento adecuado, no asumimos ninguna responsabilidad. Se proporcionará compensación al informar de los servicios y no se entregará dinero en efectivo a los clientes. El cliente puede recibir actualizaciones periódicas a través de llamadas / SMS / correos electrónicos / notificación de la aplicación. Esta política de privacidad describe cómo manejamos la información personal que recopilamos a través de nuestra aplicación. Qué información personal recopilamos: * Datos de identidad como nombre e imágenes * Información de contacto, incluido el correo electrónico * Habla a * Otra información relevante para encuestas y / u ofertas de clientes. Qué hacemos con su información personal: En particular por las siguientes razones: * Mantenimiento de registros * Para ver su historial de pedidos. * podemos utilizar la información para mejorar nuestro servicio.  </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;
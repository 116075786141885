import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Particles from 'react-particles-js';
import { FiChevronUp } from "react-icons/fi";
// import CounterOne from "../elements/counters/CounterOne";
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
// import Team from "../elements/Team";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'BOTIKIR',
        description: 'DE TODO PARA TU TRATAMIENTO',
        buttonText: '',
        buttonLink: ''
    }
]

class HomeParticles extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <Helmet pageTitle="BOTIKIR" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo" src="/assets/images/logo/logo.png" alt="Botikir"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                
                <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                        <Particles
                            params={{
                                "particles": {
                                    "number": {
                                        "value": 50
                                    },
                                    "size": {
                                        "value": 4
                                    }
                                },
                                "interactivity": {
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "repulse"
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="bg_image bg_image--27">
                            {SlideList.map((value , index) => (
                                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--1" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/about/about-3.jpg" alt="Botikir App"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">¿Qué es Botikir?</h2>
                                            <br/>
                                            <h4 className="title">Botikir es un app desarrollada con el apoyo de grandes casas medicas para brindar a los Guatemaltecos una herramienta en línea con la cual llevar sus tratamiento con los mejores profesionales y medicamentos a precios cómodos y de manera sencilla.</h4>
                                            <br/>
                                            <h5 className="title">Es fácil de usar, para usuarios, mensajeros, y médicos; la solución completa para ayudar a sentirte mejor.</h5>
                                            <br/>
                                            <p className="description">Para comprar los mejores insumos y medicamentos a precios cómodos: <br/>1)<b> Busca</b> por descripción o tipo<br/>2)<b> Ordena</b> y paga por la app <br/>3)<b> Recibe</b> tu orden a domicilio.</p>
                                            <br/>
                                            <p className="description">Para agendar citas presenciales o por video-conferencia con los mejores profesionales: <br/>1)<b> Busca</b> por nombre o especialidad<br/>2)<b> Reserva</b> y paga por la app <br/>3)<b> Asiste</b> virtual o presencialmente a tu consulta.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <WhatsAppWidget phoneNumber='+50259901488' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Botikir' sendButton='Enviar'/> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--1" id="team">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">¿Quiénes somos?</h2>
                                    <p> Esta app nace de una colaboración entre grandes casas médicas, proveedores locales, tecnólogos y emprendedores para brindarle una mejor forma de llevar su tratamiento a los pacientes crónicos.</p>
                                    <p>	Botikir es una aplicación fácil de usar que permite a los usuarios buscar y pedir fácilmente cualquier artículo de cuidado personal. Los usuarios de pedidos de medicamentos también pueden cargar una imagen de prescripción para mayor comodidad.</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div> */}
                    </div>
                </div>
                {/* End Team Area  */}

                <div className="rn-team-area ptb--120 bg_color--1" id="team">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">¿Tienes preguntas?</h2>
                                    <p><b>¿Puedo saber si su farmacia tiene licencia?</b></p>
                                    <p>Ciertamente, somos una farmacia con licencia que ofrece medicamentos recetados en línea.</p>
                                    <br/>
                                    <p><b>¿Aceptan planes de seguro?</b></p>
                                    <p>Si, aunque esto conlleva un tramite por lo cual sugerimos te contactes primero con tu seguro y con nosotros a hola@botikir.com ya que también podemos solo proporcionar una factura para su pedido para reclamar su seguro.</p>
                                    <br/>   
                                    <p><b>¿Por qué la imagen que se muestra en la aplicación es diferente del artículo que recibí?</b></p>
                                    <p>Aunque hacemos todo lo posible para garantizar que las imágenes del producto en nuestra aplicación sean idénticas a las del artículo recibido, ocasionalmente el color real del producto puede variar de la imagen que se muestra. No podemos garantizar la representación exacta del producto.</p>	 
                                    <br/>
                                    <p><b>¿Existe algún límite en la cantidad de recetas que puedo cargar?</b></p>
                                    <p>De momento, por política interna de la empresa solo se pueden cargar un máximo de 3 recetas en un pedido. 
                                    Sin embargo, puede cargar las prescripciones restantes en un nuevo pedido.</p>
                                    <br/>   
                                    <p><b>¿Qué pasa si no puedo encontrar los productos / medicamentos que estoy buscando en su aplicación?</b></p>
                                    <p>Botikir dispone de una amplia gama de stocks de productos farmacéuticos y otros productos sanitarios. Sin embargo, si no encuentra lo que necesita, no dude en comunicarse con nuestros farmacéuticos enviando un correo electrónico a hola@botikir.com. Nos comunicaremos con usted a la brevedad para informarle la disponibilidad de los medicamentos / productos solicitados.</p> 
                                    <br/>
                                    <p><b>¿Cómo se empaqueta mi pedido?</b></p>
                                    <p>Botikir pone el mayor cuidado posible al empaquetar su pedido. Tu pedido estará empaquetado de manera perfecta.</p> 
                                    <br/>
                                    <p><b>¿Cuánto tiempo tardará en entregar mi pedido?</b></p>
                                    <p>El tiempo de entrega puede variar según el lugar de entrega y el tipo de producto que solicitó.</p>
                                    <br/>
                                    <p><b>¿Cómo sabré el estado de mi pedido?</b></p>	
                                    <p>Puede verificar el estado actual de su pedido iniciando sesión en su cuenta. Y también enviamos la notificación de empujador para cada cambio de estado.</p>	 
                                    <br/>
                                    <p><b>¿Qué productos de seguridad contra el coronavirus puedo comprar en Botikir?</b></p>
                                    <p>También tenemos una amplia gama de mascarillas y desinfectantes que estaremos agregando para que usted y su familia estén protegidos. Puede explorar estos productos de atención médica y recibirlos en la puerta de su casa. ¡Mantente a salvo, mantente protegido!</p>
                                    <br/>
                                    <p><b>¿Qué hacer si he recibido productos dañados?</b></p>
                                    <p>Si recibe productos dañados / incorrectos, puede contactarnos (con la imagen requerida) enviando un correo electrónico a hola@botikir.com. Nuestro personal se comunicarán con usted en un plazo de 24 horas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-team-area ptb--120 bg_color--1" id="team">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">¿Cómo contactarnos?</h2>
                                    <br/>
                                    <div className="inner text-center">
                                        <div className="text">
                                         <h4>Teléfono: <br/> +502 2259-0811</h4>
                                         <h4>Correo Electrónico: <br/> <a href="mailto:hola@botikir.com">hola@botikir.com</a></h4>
                                         <h4>Dirección: <br/> 4ta Calle Zona 10, Ciudad de Guatemala, Guatemala 01010</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Brand Area */}
                 <div className="rn-brand-area bg_color--1 ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                <h2>Obtenla en:</h2>
                                <br/>
                                    <BrandTwo />
                                </div>
                                <br/>
                                <p>Contáctanos a <a href="mailto:hola@botikir.com">hola@botikir.com</a> para ofrecer tus servicios medicos o aplicar a ser repartidor, proveedor o socio comercial.</p>
                            </div>
                        </div>
                    </div>
                {/* End Brand Area */}

                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;